import React from 'react'
import Layout from '../components/layout/Layout'
import SEO from '../components/generic/Seo'
import Teaser from '../components/artboxone/Teaser'
import HHAllePage from './hh_alle'
import CTAButton from '../components/generic/CTAButton'
import LandingPagePues from '../components/products/LandingPagePues'
import Helmet from 'react-helmet'
import Breadcrumb from '../components/artboxone/Breadcrumb'
import { AlternateUrl, BreadcrumbLayer, PageInformation } from '../types'
import { buildAlternativeUrlsForLps } from '../utils/UrlUtils'
import {env} from '../../environment'

const HHModellPage = (options: any) => {
  const lp = options.pageContext.lp

  let seoTitle = 'Design-Handyhüllen für dein %t | artboxONE'
  let seoDescription =
    'Handverlesene Designs als Handyhülle: Mach Dein %t zu deinem neuen Lieblings-Accessoire! Individueller Look und bester Schutz perfekt kombiniert. Jetzt entdecken!'

  let title = 'Design-Handyhüllen für dein %t'
  let description =
    'Handverlesene Designs als Handyhülle: Mach Dein %t zu deinem neuen Lieblings-Accessoire! Individueller Look und bester Schutz perfekt kombiniert.'

  const manufacturer = lp.manufacturer
  const model = lp.model
  seoTitle = seoTitle.replace('%t', model)
  seoDescription = seoDescription.replace('%t', manufacturer + ' ' + model)
  title = title.replace('%t', model)
  description = description.replace('%t', manufacturer + ' ' + model)

  // let canonicals: any = []
  // options.pageContext.lps.map((lp: any) => {
  //   canonicals.push(
  //     <link
  //       rel="alternate"
  //       key={'alternate-' + lp.url_model}
  //       hrefLang={'de-DE'}
  //       href={'/handyhuellen/' + lp.url_manufacturer + '/' + lp.url_model}
  //     />,
  //   )
  // })
  // canonicals.map((canonical: any) => canonical)
  //

  const breadcrumbLayers: BreadcrumbLayer[] = []

  breadcrumbLayers.push({
    url: '/geschenke',
    name: 'Geschenke',
  })

  breadcrumbLayers.push({
    url: '/geschenke/handyhuellen',
    name: 'Handyhüllen',
  })

  breadcrumbLayers.push({
    url: '/geschenke/handyhuellen/' + lp.url_manufacturer,
    name: manufacturer,
  })

  breadcrumbLayers.push({
    url: '/geschenke/handyhuellen/' + lp.url_manufacturer + '/' + lp.url_model,
    name: model,
  })

  const pageInformation: PageInformation = { type: 'hh', material: 'cases' }

  const canonicals: any = []
  canonicals.push(
    <link
      href={
        env.getBaseUrl() +
        options.path
      }
      key="canonical-lp"
      rel="canonical"
    />,
  )

  buildAlternativeUrlsForLps('/', {de: options.path, en: '', ch: options.path}).map((alternateUrl: AlternateUrl) => {
    canonicals.push(
      <link
        href={alternateUrl.url}
        hrefLang={alternateUrl.locale}
        key={'sitemap-' + alternateUrl.url}
        rel="sitemap"
      />,
    )
  })


  return (
    <>
      <Helmet>
        <meta content="index,follow" name="robots" />
        {canonicals.map((canonical: any) => canonical)}
      </Helmet>
      <Layout pageInformation={pageInformation}>
        <SEO description={seoDescription} title={seoTitle} />
        <Breadcrumb layers={breadcrumbLayers} />
        <div className="container">
          <div className="row">
            <div className="col">
              <Teaser body={description} head={title} />
              <h2>
                Handyhüllen für {manufacturer} {model}
              </h2>
              <LandingPagePues
                hidePagination={true}
                material="cases"
                searchQuery=""
                urlfilter=""
                variant={options.pageContext.variant}
              />
              <CTAButton
                context="hh_lp_model"
                link={
                  '/geschenke/handyhuellen/alle/bestseller/2#variant=' +
                  options.pageContext.variant
                }
                title={'Weitere Handyhüllen für ' + lp.model}
              />
              <p>&nbsp;</p>
              <h2>Handyhüllen für andere {manufacturer}-Geräte</h2>
              <p>
                Gehe zurück zu allen Hüllen für {manufacturer}-Smartphones, um
                eine Übersicht der Modelle zu erhalten.
              </p>
              <p>
                <CTAButton
                  context="hh_lp_model"
                  link={'/handyhuellen/' + lp.url_manufacturer}
                  title={'Zurück zu ' + manufacturer + ' Handyhüllen'}
                />
              </p>
              <p>&nbsp;</p>

              <HHAllePage />
              <p>&nbsp;</p>

              <h2>
                artboxONE Edition für {manufacturer} {model} Hüllen
              </h2>
              <LandingPagePues
                hidePagination={true}
                material="cases"
                searchQuery=""
                urlfilter="artboxone-edition"
                variant={options.pageContext.variant}
              />
              <CTAButton
                context="hh_lp_model"
                link={
                  '/geschenke/handyhuellen/artboxone-edition/bestseller/2#variant=' +
                  options.pageContext.variant
                }
                title={'Weitere artboxONE Edition Hüllen für ' + lp.model}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default HHModellPage
